<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import { notificationMethods } from "@/state/helpers";
import { userService } from "@/services/user.service";
export default {
  data() {
    return {
      loading: false,
      errors: {},
      user: {
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        referrer_username: this.$route.params.username,
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      termsAndConditions: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  validations: {
    user: {
      username: { required },
      email: { required, email },
      password: { required },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
      referrer_username: {},
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  mounted() {
    // if (this.$store.state.auth.user && this.$route.params.username) {
    //   // trying to access the register page as an auth user
    //   this.$router.push('/') // redirect to dasboard
    // }
  },
  methods: {
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, username, password } = this.user;
        if (email && username && password) {
          try {
            this.$store.dispatch("notification/clear");
            this.errors = {};
            this.loading = true;
            // this.user.password_confirmation = this.user.password;
            var response = await userService.register(this.user);

            console.log(response);
            this.$store.dispatch("notification/success", response.data.message);
            this.$store.dispatch("auth/registeruser", this.user);
            this.$router.push({
              name: "verify-email",
              params: { email: this.user.email },
            });

            this.loading = false;
          } catch (error) {
            this.loading = false;
            console.log(error);
            switch (error.response.status) {
              case 422:
                console.log(error.response.data.errors);
                this.errors = error.response.data.errors;
                break;
              case 500:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              default:
                this.$store.dispatch(
                  "notification/error",
                  "Something went wrong. Please try again!"
                );
                break;
            }
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div style="padding-top: 50px">
                          <a href="#" class="log">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-2">Register account</h4>
                        <p class="text-muted">
                          Signup for your CadLands Affiliate Account.
                        </p>
                      </div>

                      <div class="p-2 mt-2">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-1"
                          variant="success"
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >Registration successfull.</b-alert
                        >

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-1"
                          variant="danger"
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >{{ regError }}</b-alert
                        >

                        <b-alert
                          :variant="notification.type"
                          class="mt-1"
                          v-if="notification.message"
                          show
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >{{ notification.message }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToRegisterIn"
                        >
                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">My Username</label>
                            <input
                              v-model="user.username"
                              type="text"
                              class="form-control"
                              id="username"
                              @keydown.space.prevent
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.username.$error) ||
                                  errors.username,
                              }"
                              placeholder="Choose username"
                            />
                            <div
                              v-if="submitted && !$v.user.username.required"
                              class="invalid-feedback"
                            >
                              Username is required.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="errors.username"
                            >
                              {{ errors.username[0] }}
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.email.$error) ||
                                  errors.email,
                              }"
                            />
                            <div
                              v-if="submitted && $v.user.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.user.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.user.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                            <div class="invalid-feedback" v-if="errors.email">
                              {{ errors.email[0] }}
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.password.$error) ||
                                  errors.password,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>

                            <div
                              v-if="errors.password"
                              class="invalid-feedback"
                            >
                              {{ errors.password[0] }}
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Repeat Password</label>
                            <input
                              v-model="user.password_confirmation"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Repeat password"
                              :class="{
                                'is-invalid':
                                  (submitted &&
                                    $v.user.password_confirmation.$error) ||
                                  errors.password_confirmation,
                              }"
                            />
                            <div
                              v-if="
                                (submitted &&
                                  $v.user.password_confirmation.$error) ||
                                errors.password_confirmation
                              "
                              class="invalid-feedback"
                            >
                              <span
                                v-if="!$v.user.password_confirmation.required"
                                >Please enter password confirmation.
                                &nbsp;</span
                              >
                              <span
                                v-if="
                                  !$v.user.password_confirmation.sameAsPassword
                                "
                                >Password does not match.</span
                              >
                              <span v-if="errors.password_confirmation"
                                >{{
                                  errors.password_confirmation[0]
                                }}
                                &nbsp;</span
                              >
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="referrer_username">Referrer</label>
                            <input
                              :readonly="
                                $route.params.username &&
                                !errors.referrer_username
                              "
                              v-model="user.referrer_username"
                              type="text"
                              class="form-control"
                              id="referrer_username"
                              :class="{
                                'is-invalid':
                                  (submitted &&
                                    $v.user.referrer_username.$error) ||
                                  errors.referrer_username,
                              }"
                              placeholder="Referred by .."
                            />
                            <div
                              class="invalid-feedback"
                              v-if="errors.referrer_username"
                            >
                              {{ errors.referrer_username[0] }}
                            </div>
                          </div>
                          <div class="mt-2 custom-control custom-checkbox">
                              <input type="checkbox" id="terms" class="custom-control-input" v-model="termsAndConditions" required />
                              <label
                              class="custom-control-label"
                              for="terms"
                              > 
                              I have read and agree to the Cadlands
                              <router-link
                                tag="a"
                                to="/pages/terms-and-conditions"
                                class="text-primary"
                                target="_blank"
                                >Terms and Conditions</router-link
                              >
                              </label>
                          </div>
                          <br />
                          <div class="text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                              :disable="loading || !termsAndConditions"
                            >
                              Register
                              <b-spinner
                                v-show="loading"
                                small
                                class="align-middle"
                              ></b-spinner>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="text-center">
                        <p>
                          Already have an account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Login</router-link
                          >
                        </p>
                        <p>
                          © {{ currentYear }} Cadlands. 
                          <!-- Powered by
                          <a href="https://encodeup.com" target="blank"
                            >Encodeup</a
                          > -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>